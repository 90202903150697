.contact-banner {
  background-image: url("../../images/contactBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 25px solid #ed1c24;
  height: 40vh;
}
h1.py-5 {
  padding-top: 87px !important;
  text-align: center;
  color: white;
}
input#contact-from-input {
  font-size: 15px;
  line-height: 56px;
  border: none;
  border-bottom: 1px solid black;
  padding: 10px 0px;
}
input#contact-from-input:focus {
  outline: none;
}
button#contact-from-button {
  line-height: 15px;
  font-size: 15px;
  width: 30%;
  border-radius: 5px;
  border: none;
  background: #ffffff;
  padding: 14px;
  font-weight: 700;
  box-shadow: -1px 5px 5px 0px #c7c7c7;
  color: black;
}
form#contact-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 90%;
  margin-bottom: 15px;
}
#mail-form-id {
  color: black;
  margin-top: 47px !important;
  text-align: center;
}

.form-controls {
  color: black;
  display: flex;
  font: normal normal normal 13px / 0px Poppins;
  margin-top: 27px;
  text-align: left;
  margin-left: -116px;
  flex-direction: column;
  gap: 39px;
}
.form-controls h1 {
  font: normal normal 600 18px/38px Poppins;
}
#contact-form-icon {
  color: white;
  background-color: #ed1c24;
  padding: 14px;
  border-radius: 50%;
  position: absolute;
  left: 232px;
  margin-top: 3px;
  font-size: 16px;
}
.container-fluid.footer.text-white.py-5 {
  text-align: left !important;
  line-height: normal !important;
  margin-top: 25px;
}
p#address {
  line-height: 25px;
  margin-top: -16px;
}
/* media quary started here */
@media (min-width: 320px) and (max-width: 425px) {
  .form-controls {
    color: black;
    display: flex;
    font: normal normal normal 13px / 0px Poppins;
    margin-top: 8px;
    text-align: left;
    margin-left: -1px;
    flex-direction: column;
    gap: 31px;
  }
  #contact-form-icon {
    display: none;
  }
  button#contact-from-button {
    line-height: 15px;
    font-size: 15px;
    width: 100%;
    border-radius: 5px;
    border: none;
    background: #ffffff;
    padding: 14px;
    font-weight: 700;
    box-shadow: -1px 5px 5px 0px #c7c7c7;
    color: black;
  }
}
