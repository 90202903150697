.mainsection {
  position: relative;
  z-index: 2;
}
.listdate {
  background: linear-gradient(to right, #9999, #f9f9f9);
  padding: 58px;
  text-align: center;
  margin: 23px 144px;
  border-radius: 12px;
}
h2.todaynew {
  text-align: center;
  color: #293895;
  font-size: 71px;
  padding-top: 10px;
}
.listdate a {
  background: red;
  padding: 8px 24px;
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 600;
}
.listdate h3 {
  font-size: 36px;
  margin-bottom: 36px;
  margin-top: -9px;
}
.listdate p {
  margin-top: 28px;
  font-size: 19px;
  font-weight: 700;
}

@media (min-width: 320px) and (max-width: 425px) {
  body {
    overflow: scroll !important;
  }
  .listdate {
    margin: 23px 0px;
  }
}
