/* Container */
.signup-container {
  position: relative; /* Ensure form content is above the overlay */
  z-index: 2; /* Keep form content above the background and overlay */
  max-width: 400px;
  margin: 100px auto;
  padding: 40px;
  border: none;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  font-family: "Arial", sans-serif;
  backdrop-filter: blur(12px);
  transition: all 0.3s ease;
}

/* Logo */
.logocnt img {
  width: 70%;
  margin-bottom: 30px;
}

/* Form */
.signup-container form {
  display: flex;
  flex-direction: column;
}

/* Label and Input */
.signup-container label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

/* Adjust spacing between input fields */
.signup-container input {
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px; /* Added margin to create spacing between fields */
  transition: border-color 0.3s ease;
  font-size: 14px;
  width: 100%;
}

.signup-container input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.4);
}

/* Button */
.signup-container button {
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 18px;
  margin-top: 10px; /* Added margin to create spacing above button */
}

.signup-container button:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 15px rgba(0, 91, 179, 0.4);
  transform: scale(1.02);
}

/* Error and Success Messages */
.signup-container p {
  text-align: center;
  margin-top: 12px;
  font-size: 14px;
}

.signup-container p.error {
  color: red;
  font-weight: bold;
}

.signup-container p.success {
  color: green;
  font-weight: bold;
}

/* Link Styling */
.signup-container a {
  display: inline-block;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  transition: color 0.3s ease;
  text-align: center;
}

.signup-container a:hover {
  color: #0056b3;
  text-decoration: underline;
}
.emgap {
  display: flex;
  gap: 76px;
  align-items: center;
}
.pwdgap {
  display: flex;
  gap: 44px;
  align-items: center;
}
.logocnt {
  text-align: center;
}

/* Background */
/* body {
  background-image: url("../../images/garvitbanner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
} */
/* body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-color: rgb(0 0 0 / 65%);
  z-index: 1;
} */
/* Extra: Add some padding for smaller screen responsiveness */
/* @media (max-width: 768px) {
  .signup-container {
    padding: 20px;
    margin: 66px 12px;
  }

  .signup-container input {
    padding: 12px;
    width: 170px;
    margin-left: -35px;
  }

  .signup-container button {
    padding: 12px;
  }
  body {
    height: 100vh;
  }
} */

@media (min-width: 320px) and (max-width: 425px) {
  body {
    height: 100%;
    overflow: hidden !important;
  }
  .signup-container {
    margin: 36px auto;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .signup-container input {
    margin-left: -37px;
  }
}
