/* #home-container {
  background-image: url("../../images/newspaper.png");
  background-repeat: repeat;
  background-size: cover;
  background-position: top;
  height: 139vh;
  position: absolute;
  top: -241px;
}
#newspaper {
  width: 100%;
  margin-top: -237px;
}
#newspaperlong {
  width: 100%;
  margin-top: -56px;
} */
/* new css started here */
html {
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
}

input,
a,
p,
li {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

header.masthead {
  position: relative;
  background: url("../../images/garvitbanner.jpg")
    /*black no-repeat center center scroll*/;
  background-size: cover;
  background-position: center;
  padding-top: 12rem;
  padding-bottom: 8rem;
}

header.masthead .overlay {
  position: absolute;
  background-color: #000000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
}

header.masthead h1 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

.showcase .showcase-text {
  padding: 3rem;
}

.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.testimonials {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.testimonials .testimonial-item {
  max-width: 18rem;
}

.testimonials .testimonial-item img {
  max-width: 12rem;
  -webkit-box-shadow: 0px 5px 5px 0px #adb5bd;
  box-shadow: 0px 5px 5px 0px #adb5bd;
}

.call-to-action {
  position: relative;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.video-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  width: 200px;
  margin: 0 auto;
}

.call-to-action .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.video-div {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
img#news-paper {
  margin-left: auto;
  margin-right: auto;
  transition: 700ms;
}
img#news-paper:hover {
  transform: scale(1.1);
  cursor: pointer;
}
/* iframe {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
} */
/* new css ended here */
@media (min-width: 320) and (max-width: 425px) {
  h1.react-reveal.mb-5 {
    font-size: 25px !important;
    text-align: left;
  }
  header.masthead h1 {
    font-size: 2rem !important;
    text-align: left;
  }
  .showcase .showcase-img {
    min-height: 15rem !important;
    background-size: cover;
  }
}
