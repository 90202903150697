.mainhding {
  background: #ffde00;
  border-bottom-right-radius: 26px;
}
.firstrow {
  background: #ed1c24;
}
.row.secondrow {
  background: #fff;
}
.row.secondrow .col-md-6 {
  text-align: center;
}
.row.secondrow .col-md-6 img {
  width: 100%;
  margin: 15px 0px;
}
.row.thirdrow ul {
  display: flex;
  gap: 37px;
  text-align: center;
  padding-top: 5px;
}
.row.thirdrow ul li {
  color: #fff;
  list-style: none;
  font-weight: 600;
}
.row.thirdrow ul li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}
.row.thirdrow ul li a:hover {
  text-decoration: underline;
}
.row.thirdrow {
  background: #ed1c24;
  height: 5.3vh;
}
.MuiFormControl-root.MuiTextField-root {
  position: absolute !important;
  right: 35px !important;
  margin-top: -72px !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
.row.firstrow .col-md-6 {
  padding-left: 0;
}
.mainhding p {
  font-size: 14px;
  word-spacing: 7px;
  padding-left: 15px;
  font-weight: 600;
}
.mainhding span {
  padding-left: 39px;
}
.rate p {
  margin: 0;
  font-size: 14px;
  word-spacing: 4px;
  padding-left: 15px;
  font-weight: 600;
  color: #fff;
  margin-top: -15px;
}
.rate span {
  padding-left: 8px;
}
.row.secondrow .col-md-3 {
  text-align: center;
}
.row.thirdrow p {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  word-spacing: 5px;
  padding-top: 5px;
}
.MuiFormControl-root.MuiTextField-root label {
  color: #fff !important;
  background: #ff0000d6 !important;
  border-radius: 6px !important;
  padding: 2px 7px !important;
}
.MuiFormControl-root.MuiTextField-root input {
  border: 1px solid #fff !important;
  border-radius: 6px !important;
  background: #ffffffa3 !important;
}
.row.secondrow img {
  width: 34%;
  margin: 8px 0px;
}
.social-iconss i.fab {
  color: #fff;
  border: 1px solid;
  padding: 7px;
  border-radius: 31px;
  width: 33px;
  display: flex;
  justify-content: center;
}
.social-iconss {
  float: right;
  margin-right: -4px;
  padding-top: 6px;
  display: flex;
  gap: 13px;
}
.social-iconss a {
  text-decoration: none;
}

#hindi-title li .active {
  color: #293895;
  font-weight: bold;
  text-decoration: underline;
}

@media (min-width: 320px) and (max-width: 425px) {
  ul#hindi-title {
    margin-left: -30px;
    gap: 12px;
    font-size: 13px;
  }
  h2.todaynew {
    margin-top: 0px;
    color: #212529 !important;
    font-size: 30px !important;
    margin-bottom: 16px;
  }
  .MuiFormControl-root.MuiTextField-root {
    position: relative !important;
    left: 86px !important;
    margin-top: 0px !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    margin-bottom: 5px !important;
  }
}
