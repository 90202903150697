#about-sec {
  background-color: black;
  color: white;
}
.about-us {
  padding: 64px 2px;
  height: 200px;
  border-bottom: 30px solid #ed1c24;
  background-image: url("../../images/aboutBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.about-us h1 {
  text-align: center;
}
div#about-us-sec-2 {
  background: white;
  color: black;
}
div#about-us-sec-2 h1 {
  margin-top: 30px;
  font: normal normal normal 35px/77px Poppins;
  text-align: center;
}
div#about-us-sec-2 p {
  margin-top: 30px;
  line-height: 35px !important;
  padding: 0px 100px;
  font-weight: 600 !important;
  font: normal normal normal 16px/46px Poppins;
  text-align: center;
}
div#about-us-sec-2 h2 {
  margin-top: 50px;
  font: normal normal normal 35px/77px Poppins;
  text-align: center;
}

img#editors-img {
  width: 100%;
  margin-top: -100px;
}
.social-icons1 {
  position: relative;
  bottom: 157px;
  right: -77px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  cursor: pointer;
}
.social-icons1 img {
  width: 2%;
}

.social-icons2 {
  position: relative;
  bottom: 181px;
  left: 358px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  cursor: pointer;
}
.social-icons2 img {
  width: 2%;
}

.social-icons3 {
  position: relative;
  bottom: 200px;
  left: 637px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  cursor: pointer;
}
.social-icons3 img {
  width: 2%;
}

.social-icons4 {
  position: relative;
  bottom: 221px;
  left: 917px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  cursor: pointer;
}
.social-icons4 img {
  width: 2%;
}
div#team-sec2 {
  margin-top: 70px;
  text-align: center;
  color: black;
}
div#team-sec2 p {
  line-height: 10px;
  font-size: 10px;
  text-align: left;
  font: normal normal normal 15px / 18px Poppins;
}

#testimonial-img {
  width: 100%;
}
p#para-1 {
  width: 250px;
}
p#para-2 {
  width: 250px;
}
p#para-3 {
  width: 250px;
}
.para\'s {
  display: flex;
  gap: 125px;
  margin-left: 74px;
  position: absolute;
  margin-top: 90px !important;
}
/* #footer {
  text-align: left !important;
  line-height: normal !important;
  font-size: x-large !important;
} */
.container-fluid.footer.text-white.py-5 {
  text-align: left !important;
  line-height: normal !important;
  margin-top: -262px;
}

div#underline-img {
  margin-top: -48px;
  text-align: center;
}

/* media quaryies started here */
@media (min-width: 320px) and (max-width: 425px) {
  div#about-us-sec-2 p {
    margin-top: 30px;
    line-height: 35px !important;
    padding: 0px 0px;
    font-weight: 600 !important;
    font: normal normal normal 16px / 46px Poppins;
    text-align: center;
  }
}
