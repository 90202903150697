/* .footer {
  background-color: #333;
  color: #fff;
}

.footer h4 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.footer p {
  font-size: 0.9rem;
  width: 83%;
  line-height: 28px;
}

.footer .list-unstyled h6 {
  font-weight: bold;
  font-size: 1rem;
}

.footer .social-icons a {
  color: #fff;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.footer .social-icons a:hover {
  color: #007bff;
}

.footer .form-control {
  border-radius: 0.25rem;
}

.footer button {
  border-radius: 0.25rem;
}
.container-fluid.footer {
  background: #dc3545;
  padding-bottom: 0px !important;
  padding-left: 82px;
}
@media (min-width: 320px) and (max-width: 425px) {
  .container-fluid.footer {
    background: #dc3545;
    padding-bottom: 0px !important;
    padding-left: 35px;
  }
} */

/* new css started here */
#footer-main {
  background-color: #ed1c24;
  height: 50px;
  color: white;
  padding: 2px 100px;
}
#sec-2 ul {
  display: flex;
  flex-direction: row;
  gap: 30px;
  font-size: 15px;
  line-height: 10px;
  float: right;
  margin-right: 20px;
  padding-top: 14px;
}
#sec-2 ul li {
  list-style: none;
  cursor: pointer;
}
#sec-2 ul li a {
  text-decoration: none;
  color: white;
  font-weight: 500;
}
#sec-2 ul li a:hover {
  text-decoration: underline;
  font-weight: 500;
}
#sec-1 p {
  line-height: 10px !important;
  font-size: 15px;
  color: white;
  padding-top: 16px;
  padding-left: 10px;
}
#sec-1 {
  height: 50px;
  display: flex;
  align-items: center;
}
#sec-1 i {
  font-size: 15px;
}
#sec-2 {
  height: 50px;
}

/* media quary started here */

@media (min-width: 320px) and (max-width: 425px) {
  #sec-1 p {
    line-height: 21px !important;
    font-size: 15px;
    color: white;
    padding-top: 14px;
    padding-left: 0px;
    text-align: center;
  }
  #footer-main {
    background-color: #ed1c24;
    height: 102px;
    color: white;
    padding: 2px 100px;
  }
  #sec-2 ul {
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-size: 15px;
    line-height: 10px;
    float: right;
    margin-right: 27px;
    padding-top: 14px;
  }
}
